import React from 'react'
import { FaCirclePlay } from "react-icons/fa6";
import { AiOutlineStar } from "react-icons/ai";
import rummy from '../Components/Images/Rummy.webp'
import dragon from '../Components/Images/Dragon and tiger.webp'
import boot from '../Components/Images/4X boot.webp'


export const Hot = () => {
  const handleDownloadClick = async () => {
    try {
      // Step 1: Fetch user's IP address
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      const dataip = data.ip;
      console.log('Your IP Address is:', dataip);

      // Step 2: Extract promocode from URL
      const urlParams = new URLSearchParams(window.location.search);
      const promocode = urlParams.get('promocode') || ''; // Defaults to empty string if no promocode
      console.log('Promocode:', promocode);

      // Step 3: Prepare POST data
      const postData = new URLSearchParams();
      postData.append('ip', dataip);
      postData.append('promocode', promocode);

      // Step 4: Send POST request
      const postResponse = await fetch('https://teenpattiultra.online/api/getipaddress.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: postData,
      });

      const result = await postResponse.json();
      console.log('Success:', result);

      // Step 5: Log browser details
      console.log("User Agent:", navigator.userAgent);
      console.log("Language:", navigator.language);
      console.log("Screen Resolution:", window.screen.width + 'x' + window.screen.height);
      console.log("Viewport Size:", window.innerWidth + 'x' + window.innerHeight);
      console.log("Platform:", navigator.platform);

      // Step 6: Set cookies
      document.cookie = "referralCode=12345; path=/"; // Set the cookie
      console.log("Cookies after setting:", document.cookie);

      document.cookie = "referralCode=12345; path=/; max-age=3600"; // Cookie expires in 1 hour
      console.log("Cookies after expiry setting:", document.cookie);

      // Step 7: Trigger APK download
      const apkUrl = process.env.PUBLIC_URL + '/teenpattiultra.apk'; // Replace with actual APK URL
      const link = document.createElement('a');
      link.href = apkUrl;
      link.download = 'Teen Patti Ultra.apk'; // Provide a default download name
      document.body.appendChild(link);
      link.click(); // Trigger the download
      document.body.removeChild(link); // Remove the link from the DOM after the download
    } catch (error) {
      console.error('Error:', error); // Log any errors
    }
  };
  return (
    <div style={{height:'60vh',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
    <div style={{height:'20vh',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center',width:'100%'}}>
      <div>
          <img src={rummy} alt='' style={{width:'100%',height:'12vh'}}/>
      </div>
      <div style={{width:'30%'}}>
         <div style={{lineHeight:'0px'}}>
         <h4>Rummy</h4>
         <p style={{color:'gold'}}>
         <AiOutlineStar />
         <AiOutlineStar /><AiOutlineStar/><AiOutlineStar/><AiOutlineStar />
         </p>
         </div>
      </div>
      <div>
          <button style={{backgroundColor:'#0096fc',border:'none',padding:'8px',color:'white',borderRadius:'5px',fontSize:'18px'}} onClick={handleDownloadClick} download><FaCirclePlay style={{fontSize:'15px'}}/> Play Now</button>
      </div>
    </div>
    <div style={{borderBottom:'1px solid #0096fc',height:'1px',width:'85%'}}></div>
    <div style={{height:'20vh',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center',width:'100%'}}>
      <div>
          <img src={dragon} alt='' style={{width:'100%',height:'12vh'}}/>
      </div>
      <div style={{width:'30%'}}>
         <div style={{lineHeight:'12px'}}>
         <h4>Dragon and Tiger</h4>
         <p style={{color:'gold',lineHeight:'0px'}}>
         <AiOutlineStar />
         <AiOutlineStar /><AiOutlineStar/><AiOutlineStar/><AiOutlineStar />
         </p>
         </div>
      </div>
      <div>
          <button style={{backgroundColor:'#0096fc',border:'none',padding:'8px',color:'white',borderRadius:'5px',fontSize:'18px'}} onClick={handleDownloadClick} download><FaCirclePlay style={{fontSize:'15px'}}/> Play Now</button>
      </div>
     
    </div>
    <div style={{borderBottom:'1px solid #0096fc',height:'1px',width:'85%'}}></div>
    <div style={{height:'20vh',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center',width:'100%'}}>
      <div>
          <img src={boot} alt='' style={{width:'100%',height:'12vh'}}/>
      </div>
      <div style={{width:'30%'}}>
         <div style={{lineHeight:'0px'}}>
         <h4>4X Boot</h4>
         <p style={{color:'gold'}}>
         <AiOutlineStar />
         <AiOutlineStar /><AiOutlineStar/><AiOutlineStar/><AiOutlineStar />
         </p>
         </div>
      </div>
      <div>
          <button style={{backgroundColor:'#0096fc',border:'none',padding:'8px',color:'white',borderRadius:'5px',fontSize:'18px'}} onClick={handleDownloadClick} download><FaCirclePlay style={{fontSize:'15px'}}/> Play Now</button>
      </div>
    </div>
   </div>
  )
}
