import React from 'react'

import { MdOutlineCloudDownload } from "react-icons/md";
export const Downloadbtn = () => {
  const handleDownloadClick = async () => {
    try {
      // Step 1: Fetch user's IP address
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      const dataip = data.ip;
      console.log('Your IP Address is:', dataip);

      // Step 2: Extract promocode from URL
      const urlParams = new URLSearchParams(window.location.search);
      const promocode = urlParams.get('promocode') || ''; // Defaults to empty string if no promocode
      console.log('Promocode:', promocode);

      // Step 3: Prepare POST data
      const postData = new URLSearchParams();
      postData.append('ip', dataip);
      postData.append('promocode', promocode);

      // Step 4: Send POST request
      const postResponse = await fetch('https://teenpattiultra.online/api/getipaddress.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: postData,
      });

      const result = await postResponse.json();
      console.log('Success:', result);

      // Step 5: Log browser details
      console.log("User Agent:", navigator.userAgent);
      console.log("Language:", navigator.language);
      console.log("Screen Resolution:", window.screen.width + 'x' + window.screen.height);
      console.log("Viewport Size:", window.innerWidth + 'x' + window.innerHeight);
      console.log("Platform:", navigator.platform);

      // Step 6: Set cookies
      document.cookie = "referralCode=12345; path=/"; // Set the cookie
      console.log("Cookies after setting:", document.cookie);

      document.cookie = "referralCode=12345; path=/; max-age=3600"; // Cookie expires in 1 hour
      console.log("Cookies after expiry setting:", document.cookie);

      // Step 7: Trigger APK download
      const apkUrl = process.env.PUBLIC_URL + '/teenpattiultra.apk'; // Replace with actual APK URL
      const link = document.createElement('a');
      link.href = apkUrl;
      link.download = 'Teen Patti Ultra.apk'; // Provide a default download name
      document.body.appendChild(link);
      link.click(); // Trigger the download
      document.body.removeChild(link); // Remove the link from the DOM after the download
    } catch (error) {
      console.error('Error:', error); // Log any errors
    }
  };

  return (
    <div>
        <button style={{backgroundColor:'red',border:'none',color:'white',fontSize:'20px',fontWeight:'bold',height:'40px',width:'100%',marginLeft:'10px'}} onClick={handleDownloadClick} download>
        <MdOutlineCloudDownload style={{fontSize:'22px'}}/>Download Apk
        </button>
    </div>
  )
}
