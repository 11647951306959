import React, { useState } from 'react'
import { Top } from './Top';
import { Hot } from './Hot';
import { More } from './More';
import { Link } from 'react-router-dom';
import banner from '../Components/Images/ultra.webp'
import { Downloadbtn } from './donloadbtn/downloadbtn';
import '../Components/Main.css'
export const Main = () => {
  const [page, setPage] = useState('A');
  return (
    <>
     <div className='bgStyles'>
      <div className='bgStyles2'>
       <div style={{position:'fixed',bottom:'0px'}}>
          <div>
            <Downloadbtn/>
         </div>
       </div>
   
    <div>
        <img src={banner} alt='' style={{ width: '100%', height: 'auto' }} />
        <div style={{ border: '2px solid #7e7e7e', height: '8vh' ,display:'flex',justifyContent:'space-around',alignItems:'center',background:'#EEEEEE'}}>
          <div onClick={() => { setPage('A') }} style={{borderRight:'2px solid #7e7e7e',width:'40%',height:'8vh',display:'flex',justifyContent:'center',alignItems:'center',fontWeight:'bold',backgroundColor:page==='A'?"#0096FC":"#EEEEEE"}}>Top Games</div>
          <div onClick={() => { setPage('B') }}  style={{borderRight:'2px solid #7e7e7e',width:'40%',height:'8vh',display:'flex',justifyContent:'center',alignItems:'center',fontWeight:'bold',backgroundColor:page==='B'?"#0096FC":"#EEEEEE"}}>Hot Games</div>
          <div onClick={() => { setPage('C') }}  style={{width:'40%',height:'8vh',display:'flex',justifyContent:'center',alignItems:'center',fontWeight:'bold',backgroundColor:page==='C'?"#0096FC":"#EEEEEE"}}>More Games</div>
        </div>
      </div>
      <div style={{backgroundColor:'white'}}>
        { 
          page === 'A' ?<><Top/></>:<></>
        }
        {
          page === 'B' ?<><Hot/></>:<></>
        }
        {
          page === 'C' ?<><More/></>:<></>
        }
      </div>
      <div style={{background:'#3b0303',height:'41vh',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
          <div style={{height:'4vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
          <p style={{color:'#1beb00'}}>teenpattiultra.online</p>
          </div>
          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center',width:'90%',height:'5vh',color:'#afafaf',fontWeight:'bold'}}>
               <p>YouTube</p>
               <p>Instagram</p>
               <p>Facebook</p>
               <p>Telegram</p>
          </div>
          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center',width:'90%',height:'5vh',color:'#afafaf',fontWeight:'bold'}}>
               <Link to='/AboutPage' style={{color:'#afafaf'}}><p>About Us</p></Link>
               <Link to='/contact' style={{color:'#afafaf'}}><p>Contact Us</p></Link>
               <Link to='/Pricing' style={{color:'#afafaf'}}><p>Pricing</p></Link>
               <Link to='/privacy' style={{color:'#afafaf'}}><p>Privacy Policy</p></Link>
              
          </div>
          <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',width:'90%',height:'5vh',fontWeight:'bold'}}>
          <Link to='/cancellation' style={{color:'#afafaf'}}><p>Cancellation and Refund Policy</p></Link>
          </div>
          <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',width:'90%',height:'5vh',color:'#afafaf',fontWeight:'bold'}}>
          <Link to='/terms' style={{color:'#afafaf'}}><p>Terms & Conditions</p></Link>
          </div>
          <div  style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',width:'90%',height:'3vh',color:'#1beb00',fontWeight:'bold',fontSize:'10px'}}>
             <p>This Game is Only meant for the 18+age group.</p>
          </div>
          <div style={{borderBottom:'1px solid #afafaf',height:'2vh',width:'100%',display:'flex',alignItems:'center'}}></div>
          <div style={{fontSize:'12px',height:'2vh',color:'#afafaf'}}>
          <p>Copyright 2023 <span style={{fontWeight:'bold'}}>teenpattiultra.online </span>All rights reserved</p>
          </div>
        </div>
        </div>
    </div>
    </>
  )
}
